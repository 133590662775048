<template>
	<v-app>
		<v-navigation-drawer permanent clipped app :width="menuWidth">
			<v-aside
				@changeMenuWidth="changeMenuWidth"
			></v-aside>
		</v-navigation-drawer>

		<v-app-bar clipped-left app>
			<v-app-bar-title><router-link to="/data/save">HQSW</router-link></v-app-bar-title>
		</v-app-bar>

		<!-- Sizes your content based upon application components -->
		<v-main>
			<!-- Provides the application the proper gutter -->
			<v-container fluid>

				<!-- If using vue-router -->
				<router-view></router-view>
			</v-container>
		</v-main>

<!--		<v-footer height="52" inset app>-->
<!--			ФУТЕР-->
<!--		</v-footer>-->
	</v-app>
</template>

<script>
export default {
	name: 'appContainer',
	data: () => ({
		menuWidth: 240
	}),

	methods: {
		changeMenuWidth (menuNarrow) {
			this.menuWidth = menuNarrow ? 72 : 240
		}
	}
}
</script>

<style scoped>

</style>
